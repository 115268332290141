const initialRouteState = {
    path: undefined,
    url: undefined,
    isExact: undefined,
    params: {},
    routeState: {},
    isFirstPage: true,
};

export default initialRouteState;
