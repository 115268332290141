const iconNames = {
    add: 'ion-plus',
    addCircle: 'ion-android-add-circle',
    addPerson: 'ion-person-add',
    assessment: 'ion-android-locate',
    applyAll: 'ion-ios-world',
    applyAllBelow: 'ion-android-arrow-dropdown-circle',
    apps: 'ion-android-apps',
    back: 'ion-android-arrow-back',
    circle: 'ion-record',
    calculator: 'ion-ios-calculator',
    calendar: 'ion-ios-calendar-outline',
    camera: 'ion-camera',
    chart: 'fa fa-sitemap',
    chatOutline: 'ion-ios-chatboxes-outline',
    chat: 'ion-chatbox-working',
    check: 'ion-checkmark',
    checkAll: 'ion-android-done-all',
    checkCircle: 'ion-checkmark-circled',
    checkOutlined: 'ion-checkmark-circled',
    checkbox: 'ion-android-checkbox',
    checkboxOutlineBlank: 'ion-android-checkbox-outline-blank',
    chevronDown: 'ion-chevron-down',
    chevronLeft: 'ion-chevron-left',
    chevronRight: 'ion-chevron-right',
    chevronUp: 'ion-chevron-up',
    downArrow: 'ion-arrow-down-a',
    chrome: 'ion-social-chrome',
    clipboard: 'ion-clipboard',
    close: 'ion-close',
    code: 'ion-code',
    codeWorking: 'ion-code-working',
    copy: 'ion-ios-copy',
    copyOutline: 'ion-ios-copy-outline',
    csv: 'fa fa-file-code-o',
    delete: 'ion-android-delete',
    discover: 'fa fa-binoculars',
    documentText: 'fa fa-file-o',
    docx: 'fa fa-file-word-o',
    download: 'fa fa-download',
    dropbox: 'ion-social-dropbox',
    edit: 'ion-edit',
    email: 'fa fa-envelope',
    error: 'ion-android-alert',
    expand: 'ion-arrow-expand',
    expandContent: 'ion-android-expand',
    filter: 'ion-ios-color-filter-outline',
    funnel: 'fa fa-filter',
    contractContent: 'ion-android-contract',
    eye: 'ion-eye',
    eyeDisabled: 'ion-eye-disabled',
    faEye: 'fa fa-eye',
    faEyeDisabled: 'fa fa-eye-slash',
    fig: 'fa fa-file-image-o',
    fork: 'ion-fork-repo',
    format: 'ion-android-list',
    album: 'ion-ios-albums',
    forward: 'ion-forward',
    globe: 'ion-earth',
    googleDrive: 'ion-social-googleplus-outline',
    grid: 'ion-grid',
    hamburger: 'ion-android-menu',
    help: 'ion-help-circled',
    helpOutlined: 'ion-ios-help-outline',
    histogram: 'ion-arrow-graph-up-right',
    history: 'fa fa-history',
    horizontalBar: 'ion-navicon',
    info: 'ion-ios-information',
    infoOutline: 'ion-ios-information-outline',
    jpeg: 'fa fa-file-image-o',
    jpg: 'fa fa-file-image-o',
    json: 'fa fa-file-code-o',
    link: 'ion-link',
    list: 'ion-ios-list-outline',
    loading: 'ion-load-c',
    locked: 'ion-locked',
    logout: 'ion-log-out',
    map: 'ion-android-map',
    minusOutline: 'ion-ios-minus-outline',
    more: 'ion-more',
    moreVertical: 'ion-android-more-vertical',
    msword: 'fa fa-file-word-o',
    next: 'ion-chevron-right',
    noSearch: 'fa fa-search-minus',
    notification: 'ion-ios-bell-outline',
    openLink: 'ion-android-open',
    otf: 'fa fa-file-word-o',
    overflowHorizontal: 'ion-android-more-horizontal',
    pdf: 'fa fa-file-pdf-o',
    person: 'ion-android-person',
    pizza: 'ion-pizza',
    png: 'fa fa-file-image-o',
    ppt: 'fa fa-file-powerpoint-o',
    pptx: 'fa fa-file-powerpoint-o',
    prev: 'ion-chevron-left',
    refresh: 'ion-android-refresh',
    remove: 'ion-android-remove',
    removeCircle: 'ion-android-remove-circle',
    rtf: 'fa fa-file-word-o',
    save: 'fa fa-save',
    search: 'ion-search',
    settings: 'ion-android-settings',
    shrink: 'ion-arrow-shrink',
    sort: 'fa fa-sort',
    sortAscending: 'fa fa-sort-asc',
    sortDescending: 'fa fa-sort-desc',
    sortAmountAscending: 'fa fa-sort-amount-asc',
    sortAmountDescending: 'fa fa-sort-amount-desc',
    sortAlphabeticalAscending: 'fa fa-sort-alpha-asc',
    sortAlphabeticalDescending: 'fa fa-sort-alpha-desc',
    spinner: 'fa fa-spinner fa-spin fa-3x fa-fw',
    pin: 'ion-pin',
    share: 'ion-android-share-alt',
    substract: 'ion-ios-minus-empty',
    swap: 'ion-arrow-swap',
    table: 'fa fa-table',
    tabular: 'ion-ios-grid-view',
    text: 'ion-document',
    textFormat: 'ion-ios-circle-filled',
    thumbsDown: 'ion-thumbsdown',
    thumbsUp: 'ion-thumbsup',
    trash: 'ion-trash-b',
    undo: 'fa fa-undo',
    upload: 'ion-android-upload',
    uploadFa: 'fa fa-upload',
    user: 'ion-android-contact',
    unarchive: 'ion-ios-upload-outline',
    unlocked: 'ion-unlocked',
    archive: 'ion-ios-download-outline',
    archiveBlock: 'ion-archive',
    userGroup: 'ion-android-contacts',
    verticalBar: 'ion-connection-bars',
    visualization: 'ion-stats-bars',
    menuDots: 'ion-android-more-vertical',
    warning: 'ion-alert-circled',
    widget: 'ion-cube',
    word: 'ion-social-wordpress-outline',
    world: 'ion-ios-world',
    wrench: 'ion-wrench',
    xls: 'fa fa-file-excel-o',
    xlsx: 'fa fa-file-excel-o',
    ods: 'fa fa-file-excel-o',
    xml: 'fa fa-file-code-o',
    people: 'ion-android-people',
    colorPalette: 'ion-android-color-palette',
    down: 'ion-ios-arrow-down',
    up: 'ion-ios-arrow-up',
    upArrow: 'ion-arrow-up-a',
    paintBrush: 'fa fa-paint-brush',
    book: 'fa fa-book',
    externalLink: 'fa fa-external-link',
    arrowForward: 'ion-ios-arrow-forward',
};

export default iconNames;
