const initialLangState = {
    selectedLanguage: undefined,
    fallbackLanguage: undefined,
    availableLanguages: [
        // { code: 'np', title: 'Nepali' },
    ],
    languages: {
        /*
        np: {
            strings: { 150: 'नाम' },
            links: {
                login: { firstNameLabel: 150 },
            },
        },
        */
    },
};
export default initialLangState;
